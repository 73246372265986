.MuiDataGrid-dragscroll {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.MuiDataGrid-dragscroll:active{
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing; 
}
.MuiDataGrid-dragscroll .MuiDataGrid-cell-content {
  cursor: auto;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}